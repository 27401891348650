import React from "react"
import styled from "styled-components"
import { SvgIcon } from "@material-ui/core"

const StyledSvg = styled(SvgIcon)`
  ${({ theme }) => `
    fill: ${theme.palette.primary.contrastText};
  `}
`

const Book = (props) => (
  <StyledSvg {...props}>
    <path d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 4h2v5l-1-.75L9 9V4zm9 16H6V4h1v9l3-2.25L13 13V4h5v16z"></path>
  </StyledSvg>
)

export default Book
