export default {
  title: "Alexandre Le Lain",
  menu: {
    welcome: "Welcome",
    about: "About me",
    experiences: "Experiences",
    projects: "Projects",
    skills: "Skills",
    contact: "Contact",
  },
  en: "English",
  fr: "Français",
}
