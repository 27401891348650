export default {
  title: "Alexandre Le Lain",
  menu: {
    welcome: "Bienvenue",
    about: "Profil",
    experiences: "Expériences",
    projects: "Projets",
    skills: "Compétences",
    contact: "Me Contacter",
  },
  en: "English",
  fr: "Français",
}
